import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ActionsRebootRavenOverlay from './actionsRebootRavenOverlay';

const ACTION_REASON_MAX_CHARACTERS = 280;
export default class Actions extends React.PureComponent {

    static propTypes = {
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        item: PropTypes.object.isRequired,
        feature: PropTypes.object.isRequired, // TODO feature contains item (above), so one prop could be passed instead of two
        oldestLoadedEventMoment: PropTypes.object, // moment.js
        mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js    
        stage: PropTypes.string.isRequired,
        ravenId: PropTypes.number
    };

    initialState = {
        selectedActionValue: null,
        selectedActionReasonValue: null,
        actionsReasonErrorOccurred: false,
        actionsSelectErrorOccurred: false,
        actionRequestInProgress: false,
        restoredActionsOverlayState: null,
        isStateRestored: false,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;
    }

    availableActions = [
        {
            value: "logrequest",
            label: "Request logs",
            id: "LOGREQUEST"
        },
        {
            value: "reboot",
            label: "Reboot raven",
            id: "REBOOT"
        },
        {
            value: "factoryreset",
            label: "Factory reset raven",
            id: "FACTORYRESET"
        }
    ];

    componentDidMount() {
        this.restoreState();
    }

    /**
     * Checks if the Raven ID has changed and restores the state from local storage if it has.
     * Possible states when entering this function:
     * 1. Raven ID has changed and there is a state to restore from local storage
     * 2. Raven ID has changed and there is no state to restore from local storage
     * 3. Raven ID has not changed
     * @param {*} prevProps - previous React props before the update
     * @param {*} prevState - previous React state before the update
     * @param {*} snapshot - snapshot value returned by getSnapshotBeforeUpdate
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        //I might want to have this set in getDerivedStateFromProps instead to adjust the state before rendering the overlay
        if (prevProps.ravenId && this.props.ravenId && prevProps.ravenId !== this.props.ravenId) {
            this.setState({ isStateRestored: false }, () => {
                this.restoreState();
                this.setState({ isStateRestored: true });
            });
        }
    }

    /**
     * Resets the state back to default settings and removes the saved state from local storage
     */
    resetState() {
        this.setState({ ...this.initialState, restoredActionsOverlayState: null });
        this.removeStateInLocalStorage();
    }


    /**
     * Restores the state from local storage if it exists. If it doesn't exist, resets the state back to default settings.
     */
    restoreState() {
        const stateToRestore = this.loadStateFromLocalStorage();
        
        if (stateToRestore) {
            //If the action was canceled or completed, reset the state. Otherwise, restore the state and continue from there.
            stateToRestore.restoredActionsOverlayState.actionCompleted ? this.resetState() : this.setState({ ...stateToRestore });
        } else {
            this.resetState();
        }
    }

    /**
     * Removes the saved state from local storage
     * This is called when the action is completed or canceled
     * @returns 
     */
    removeStateInLocalStorage = () => {

        const ravenId = this.props.item && this.props.ravenId ? this.props.ravenId : undefined;

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId; // only reboot is supported at the moment

        window.localStorage.removeItem(key);
    }

    persistStateInLocalStorage = (actionsOverlayState, oldRavenID = null) => {
        if (actionsOverlayState === this.initialState 
            || actionsOverlayState.restoredActionsOverlayState 
            && actionsOverlayState.restoredActionsOverlayState.actionCanceled) 
            return;
        
        let ravenId;
        if (oldRavenID) {
            ravenId = oldRavenID;
        }else{
            ravenId = this.props.item && this.props.ravenId ? this.props.ravenId : undefined;
        }

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId; // only one action per raven is supported

        const stateToPersist = this.state;

        stateToPersist.restoredActionsOverlayState = actionsOverlayState;

        window.localStorage.setItem(key, JSON.stringify(stateToPersist));
    }

    loadStateFromLocalStorage = () => {

        const ravenId = this.props.item && this.props.ravenId ? this.props.ravenId : undefined;

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId;

        const combinedStatesJSON = window.localStorage.getItem(key);

        if (combinedStatesJSON) {
            try {
                const combinedStates = JSON.parse(combinedStatesJSON);
                if (combinedStates && combinedStates.restoredActionsOverlayState) {
                    const actionStartTime = new Date(combinedStates.restoredActionsOverlayState.actionStartTime);
                    if (actionStartTime < Date.now() - 3 * 86400000) {
                        window.localStorage.removeItem(key);
                        return null;
                    }
                    return combinedStates;
            }
            } catch (error) {
                console.error("Failed to parse state from local storage:", error);
            }
        }
        return null;
    }

    onActionSubmit = () => {
        let validationFailed = false;
        let warning = 'Are you sure you want to perform a factory reset? This action cannot be undone and the user will lose all data on the SD card.'
        // If it's a factory reset, show confirmation prompt
        if (this.state.selectedActionValue.value === "factoryreset" 
            && !window.confirm(warning)) {
            // User clicked 'Cancel', so we stop the method execution here
            this.resetState();
            return;
        }

        if (!this.state.selectedActionValue) {
            this.setState({ actionsSelectErrorOccurred: true });
            validationFailed = true;
        } else {
            this.setState({ actionsSelectErrorOccurred: false });
        }
        if (!this.state.selectedActionReasonValue) {
            this.setState({ actionsReasonErrorOccurred: true });
            validationFailed = true;
        } else {
            this.setState({ actionsReasonErrorOccurred: false });
        }
        if (validationFailed) {
            return;
        }
        this.setState({ actionRequestInProgress: true, isStateRestored: true });
    }

    onActionClose = () => {
        this.resetState();
    }

    render() {
        let unitOffline = Date.now() - this.props.item["Last Update"] * 1000 > 60 * 10000;

        let actionsSelectClassName = "actions-select";
        let textareaClassName = "actions-reason-textarea";
        let textareaLabelClassName = "";
        let textAreaCharacterLimitClassName = "actions-reason-character-limit";

        if (this.state.actionsSelectErrorOccurred) actionsSelectClassName += " error";
        
        if (this.state.actionsReasonErrorOccurred) {
            textareaClassName += " error-border";
            textareaLabelClassName += " error-highlight";
        }

        const textAreaInputChractersLength = this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue.length : 0;
        if (textAreaInputChractersLength === ACTION_REASON_MAX_CHARACTERS) 
            textAreaCharacterLimitClassName += " max-reached";

        return (
            <section className="raven-actions">
                <header>
                    Raven Actions
                </header>
                <div className="raven-actions-container">
                    {/** Warn the user that the unit has not connected and the action may not work */}
                    {unitOffline &&
                        <div className="unit-offline-warning">
                            <span className="unit-offline-warning-text" style={{ color: "red" }}>
                                Unit is offline. Action may not be processed by Raven.
                            </span>
                        </div>
                    }
                    <div>
                        <Select
                            id="actions-select"
                            className={actionsSelectClassName}
                            name="actions-select"
                            placeholder="Select an action"
                            options={this.availableActions}
                            value={this.state.selectedActionValue}
                            onChange={(selectedActionValue) => {
                                this.setState({
                                    selectedActionValue,
                                    actionsSelectErrorOccurred: false
                                })
                            }}
                            searchable={false/* disable input */}
                        />
                    </div>
                    <div>
                        <div className={textareaLabelClassName} >Reason for action:</div>
                        <textarea
                            name="actions-reason"
                            value={this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue : ""}
                            onChange={(event) => {
                                this.setState({
                                    selectedActionReasonValue: event.target.value.substring(0, ACTION_REASON_MAX_CHARACTERS),
                                    actionsReasonErrorOccurred: false
                                })
                            }}
                            className={textareaClassName}
                        />
                        <div className={textAreaCharacterLimitClassName}>
                            <span>
                                {this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue.length : 0}/{ACTION_REASON_MAX_CHARACTERS}
                            </span>
                        </div>
                    </div>
                    <div className="form-buttons">
                        <button className="btn actions-submit" onClick={this.onActionSubmit} disabled={(!this.state.selectedActionValue || !this.state.selectedActionReasonValue > 0)} >
                            Send Request To Raven
                        </button>
                    </div>
                </div>
                {this.state.actionRequestInProgress && this.state.isStateRestored ?
                    <ActionsRebootRavenOverlay //abort controller
                        key={this.props.ravenId}
                        dataStore={this.props.dataStore}
                        raven={this.props.item}
                        feature={this.props.feature}
                        oldestLoadedEventMoment={this.props.oldestLoadedEventMoment}
                        mostRecentLoadedRavenBootedEventMoment={this.props.mostRecentLoadedRavenBootedEventMoment}
                        stage={this.props.stage}
                        reason={this.state.selectedActionReasonValue}
                        onActionClose={this.onActionClose}
                        persistStateInLocalStorage={this.persistStateInLocalStorage}
                        restoredActionsOverlayState={this.state.restoredActionsOverlayState}
                        selectedAction={this.state.selectedActionValue}
                        ravenId={this.props.ravenId}
                    />
                    :
                    null
                }
            </section>
        )
    }
}
